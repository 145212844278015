//Scroll
::-webkit-scrollbar{
  width: 0.7rem;
  height: 0.7rem;
  @media only screen and (max-width: 768px) {
    width: 0.5rem;
    height: 0.5rem;
  }
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #f4873b;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #e06f24;
  border: 3px solid #e06f24;
}

.Carousel{
  &__header{
    display: flex;
    margin-bottom: .5rem;

    @media only screen and (max-width: 768px) {
      min-height: 6rem;
    }

    &--number{
      display: flex;
      flex-direction: column;
    }

    span{
      background-color:#e06f24;
      color: white;

      font-size: 1.5rem;
      text-align: center;
      padding: .5rem .75rem;
      margin-right: 1rem;
      font-weight: 700;
      border-radius: 50%;
    }

    p {
      font-weight: 600;
    }
  }

  &__slide{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

  }

  &__button{
    position: absolute;
    cursor: pointer;
    background-color:#e06f247c;
    padding: .25rem .25rem;
    border-radius: 50%;

    &--right{
      display: flex;
      right: .5rem;
    }

    &--left{
      background-color:#cab09e7c;
      left: .5rem;
    }
  }

  &__footer{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 2rem;
    margin-top: .5rem;
  }

  &__dots{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex: 0 1 150px;
  }

  &__tooltipContainer {
    position: relative;
    display: inline-block;
  }

  &__tooltip{

    color: white !important;
    background-color: #e06f24 !important;
    border-radius: 10px !important;

    width: 200px;
    bottom: 150%;
    left: 50%;
    margin-left: -100px;

    text-align: center;
    font-weight: 600;
    padding: 1rem 0;

    @media screen and (max-width: 768px) {
      width: 70px;
      bottom: 125%;
      left: 50%;
      padding: .5rem 0;
      margin-left: -35px;
      font-size: .5rem;
    }


    position: absolute;
    z-index: 1;

    &::after{
      content: " ";
      position: absolute;
      top: 100%; /* At the bottom of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #e06f24 transparent transparent transparent;
    }
  }

  &__image{
    &--web{
      max-height: 50vh;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &--mobile{
      max-height: 50vh;
      @media screen and (min-width: 769px) {
        display: none;
      }
    }
  }

  &__footer-button {
    display: flex;
    justify-content: center;
  }
}

.about-entry {
  &__checkbox-terms {
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
    }
  }

  &__title-terms {
    text-align: center;
    color: #f4873b;
    font-size: 1.75rem;
    font-weight: 500;

  }
}

.topic-terms {
  color: #f4873b;
  font-size: 1.5rem;
  margin-top: 0.7rem;
  font-weight: 500;
}

.chakra-checkbox__control {
  border: 1px solid #bbb !important;
  background-color: #fff;
}

.chakra-checkbox__control[data-checked] {
  background: #f4873b !important;
}

.chakra-checkbox__control[data-invalid] {
  border-color: red !important;
}


.chakra-checkbox__control[data-focus] {
  box-shadow: 0 0 3px #f4873b !important;
}

.modal-terms {
  &__content {
    height: 75vh;
    overflow-y: auto;
    max-width: 50% !important;

    @media screen and (max-width: 768px) {
      max-width: 100% !important;
    }
  }
}

.checkbox-error {
  color: rgb(255, 0, 0);
  font-size: 0.8em;
}