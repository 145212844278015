@media(max-width: 766px) {
  .sm-mgTp {
    margin-top: 15px;
  }
}

:root {
  --main-bg-color: '#1ea465'
}

.rotate-center {
  -webkit-animation: rotate-center 1s linear infinite both;
  animation: rotate-center 1s linear infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-11-30 10:54:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.range-input {
  -webkit-appearance: none;
  margin: 20px 0;
  width: 100%;
}

input[type=checkbox]:checked {
  background: red;
}

.range-input:focus {
  outline: none;
}

.range-input::-webkit-slider-runnable-track {
  width: 100vh;
  height: 14px;
  cursor: pointer;
  animate: 0.2s;
  background: var(--main-bg-color);
  border-radius: 25px;
}

.range-input::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -2px;
}

.range-input:focus::-webkit-slider-runnable-track {
  background: var(--main-bg-color);
}

.range-wrap {
  width: 100%;
  position: relative;
}

.range-value {
  position: absolute;
  top: -50%;
}

.range-value span {
  width: 100px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: var(--main-bg-color);
  color: #fff;
  font-size: 12px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 6px;
}

.range-value span:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 10px solid var(--main-bg-color);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}

.initial-value {
  font-size: 100px;
  font-weight: 100;
}

@media(max-width: 990px) {
  .initial-value {
    font-size: 50px;
    font-weight: 100;
  }
}

@media(max-width: 400px) {
  .initial-value {
    font-size: 25px;
    font-weight: 100;
  }
}

.p-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

@media(max-width: 600px) {
  .table-content {
    font-size: xx-small !important;
    background-color: chartreuse !important;
  }
}

@media(max-width: 880px) {
  .table-div {
    width: 90%;
  }
}

@media(min-width: 890px) {
  .table-div {
    display: flex;
    justify-content: center;
  }
}

.react-datepicker-wrapper {
  display: flex;
}

.react-datepicker-custom {
  border: 1px solid #E2E8F0;
  height: 32px;
  padding-left: 10px;
  width: 100%;
  background-color: #f8f8f9;
}

.react-datepicker-custom:focus {
  outline: none;
  border: 1px solid #f4873b;
  box-shadow: 0 0 0 1px #f4873b;
}

.react-datepicker-custom__error{
  outline: none;
  border: 1px solid #F56565;
  box-shadow: 0 0 0 1px #f56565;
}

.birthdate-error {
  color: rgb(255, 0, 0);
  font-size: 0.8em;
  padding-top: 5px;
}